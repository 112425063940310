<template>
  <div id="navbar-custom">
    <b-navbar>
      <b-button v-if="isLogin" v-b-toggle.side-menu variant="transparent">
        <div class="hamberger" />
        <div class="hamberger" />
        <div class="hamberger" />
      </b-button>
      <b-navbar-brand>
        <img src="@/static/images/logo-default.png" alt="logo" />
      </b-navbar-brand>
    </b-navbar>

    <b-sidebar id="side-menu" title="Sidebar" no-header shadow backdrop @change="toggleBodyScrollbar">
      <div>
        <nav class="mb-3">
          <b-nav vertical>
            <b-nav-item href="/history"> ประวัติใบคำขอ </b-nav-item>
            <b-nav-item v-b-toggle.collapse-side-menu @click="visible = !visible">
              จัดการผู้ใช้งาน
              <div class="arrow ml-2" :class="{ up: visible, down: !visible }" style="border-color: #ffffff" />
            </b-nav-item>
            <b-collapse id="collapse-side-menu">
              <b-nav-item href="/staff-management/index"> ผู้ใช้งานหน้าบ้าน </b-nav-item>
              <b-nav-item href="/cms-management/index"> ผู้ใช้งานหลังบ้าน </b-nav-item>
            </b-collapse>
            <b-nav-item href="/history-renewal"> ต่ออายุกรมธรรม์ </b-nav-item>
            <b-nav-item v-b-toggle.collapse-side-menu1 @click="visible1 = !visible1">
              ออกรายงาน
              <div class="arrow ml-2" :class="{ up: visible1, down: !visible1 }" style="border-color: #ffffff" />
            </b-nav-item>
            <b-collapse id="collapse-side-menu1">
              <!-- <b-nav-item v-b-modal.modal-raw-data-report> RAW Data </b-nav-item> -->
              <b-nav-item id="opus-import" @click="clickOPUSReport"> OPUS Import </b-nav-item>
              <b-nav-item id="opus-import-wht" @click="clickOPUSReportWHT"> OPUS Import (WHT) </b-nav-item>
            </b-collapse>
            <b-nav-item href="/get-risk/view"> ตรวจสอบความเสี่ยง </b-nav-item>
            <b-nav-item id="logout" @click="logout"> ออกจากระบบ </b-nav-item>
          </b-nav>
        </nav>
      </div>
    </b-sidebar>
    <!-- Raw data Report -->
    <b-modal
      id="modal-raw-data-report"
      ref="modalRawDataReport"
      size="md"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      @show="resetReportDate"
    >
      <div class="close-button" @click="$bvModal.hide('modal-raw-data-report')" />
      <div style="text-align: center; padding: 30px 16px">
        <h4 style="color: #032c63">โปรดเลือกช่วงวันที่ของรายงาน</h4>

        <b-row class="justify-content-center" style="margin-top: 60px">
          <!-- <b-col cols="12">
            <DatePicker
              id="startDateReportRawData"
              v-model="startDateReport"
              label="เลือกวันเริ่มต้น"
              :buddhist="true"
            />
          </b-col>
          <b-col cols="12">
            <DatePicker
              id="endDateReportRawData"
              v-model="endDateReport"
              label="เลือกวันสิ้นสุด"
              :buddhist="true"
            />
          </b-col> -->
          <b-col cols="12">
            <inputElement
              :id="'startDateReport'"
              v-model="startDateReport"
              :type="'date'"
              :label="'เลือกวันเริ่มต้น'"
              :is-empty="startDateReport ? 'false' : 'true'"
            />
          </b-col>
          <b-col cols="12">
            <inputElement
              :id="'endDateReport'"
              v-model="endDateReport"
              :type="'date'"
              :label="'เลือกวันสิ้นสุด'"
              :is-empty="endDateReport ? 'false' : 'true'"
            />
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col>
            <button
              class="ndbx-button custom-button secondary small-medium"
              @click="$bvModal.hide('modal-raw-data-report')"
            >
              ยกเลิก
            </button>
          </b-col>
          <b-col>
            <button
              class="ndbx-button custom-button emphasis small-medium"
              :disabled="buttonValidation"
              @click.prevent="clickRawDataReport"
            >
              ออกรายงาน
            </button>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import inputElement from '@/components/ndbx-element/Input.vue'
export default {
  name: 'NavbarCustom',
  components: {
    inputElement,
  },
  data() {
    return {
      visible: true,
      visible1: true,
      startDateReport: '',
      endDateReport: '',
    }
  },
  computed: {
    isLogin() {
      return this.$store.getters['staff/loggedIn']
    },
    buttonValidation() {
      if (this.startDateReport && this.endDateReport) {
        return false
      } else return true
    },
  },
  methods: {
    clickOPUSReport() {
      console.log('clickOPUSReport Nav')
      const params = {
        // all: true,
      }
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        lockScroll: true,
        zIndex: 9999,
        loader: 'dots',
        color: '#006192',
      })

      this.$bvModal.hide('modal-opus-report')
      this.$store.dispatch('smeFireInsurance/reportOpus', params).then((res) => {
        loader.hide()
        console.log('API report OPUS ::', res)
        if (res.status == 200) {
          this.downloadCSV(res.data, '')
        } else if (res.status == 204) {
          alert('ไม่มี transaction ใหม่')
        }
      })
    },
    clickOPUSReportWHT() {
      const params = {
        // all: true,
        wht: true,
      }

      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        lockScroll: true,
        zIndex: 9999,
        loader: 'dots',
        color: '#006192',
      })

      this.$bvModal.hide('modal-opus-report')
      this.$store.dispatch('smeFireInsurance/reportOpus', params).then((res) => {
        loader.hide()
        console.log('API report OPUS ::', res)
        if (res.status == 200) {
          this.downloadCSV(res.data, 'wht')
        } else if (res.status == 204) {
          alert('ไม่มี transaction ใหม่')
        }
      })
    },
    clickRawDataReport() {
      const params = {
        startDate: this.startDateReport,
        endDate: this.endDateReport,
      }

      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        lockScroll: true,
        zIndex: 9999,
        loader: 'dots',
        color: '#006192',
      })

      this.$bvModal.hide('modal-raw-data-report')
      this.$store.dispatch('smeFireInsurance/reportRawData', params).then((res) => {
        loader.hide()
        console.log('API report Raw Data ::', res)
        if (res.status == 200) {
          this.downloadExcel(res)
        } else if (res.status == 204) {
          alert('ไม่มี transaction ระหว่างวันที่ ' + this.startDateReport + ' ถึงวันที่ ' + this.endDateReport)
        }
      })
    },
    toggleBodyScrollbar(visible) {
      const body = document.getElementsByTagName('body')[0]

      if (visible) body.classList.add('overflow-hidden')
      else body.classList.remove('overflow-hidden')
    },
    resetReportDate() {
      this.startDateReport = ''
      this.endDateReport = ''
    },
    logout() {
      this.$store.dispatch('staff/destroyAuth')
    },
    /**
     * Export report data to xlsx file
     */
    downloadExcel(items) {
      const link = document.createElement('a')
      const url = URL.createObjectURL(
        new Blob([items.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
        })
      )

      var date = new Date().toISOString().slice(0, 10)
      var yyyy = date.split('-')[0]
      var mm = date.split('-')[1]
      var dd = date.split('-')[2]
      var filename = 'TTBProtal' + yyyy + mm + dd

      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.click()
    },

    /**
     * Export report data to CSV file
     */
    downloadCSV(items, type) {
      var data, filename, link
      var csv = items
      var date = new Date().toISOString().slice(0, 10)
      var yyyy = date.split('-')[0]
      var mm = date.split('-')[1]
      var dd = date.split('-')[2]

      if (csv == null) return

      filename = type == 'wht' ? 'quote_ttb_web_wht_' + yyyy + mm + dd : 'quote_ttb_web_' + yyyy + mm + dd
      if (!csv.match(/^data:text\/csv/i)) {
        // csv = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csv)
        csv = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
      }

      data = csv

      link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute('download', filename)
      link.click()
    },
  },
}
</script>

<style>
#navbar-custom .navbar {
  padding: 0.5rem 2rem;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

#navbar-custom .navbar-brand {
  margin-left: 16px;
}

#navbar-custom .btn {
  padding: 3px 6px !important;
}

#navbar-custom .b-sidebar {
  top: 60px !important;
  background-color: #405f88 !important;
}

#navbar-custom .b-sidebar li {
  padding: 0.75rem !important;
  text-align: left !important;
  border: 1px solid #405f88 !important;
  background-color: #032c63 !important;
}

#navbar-custom .b-sidebar li:hover,
#navbar-custom .b-sidebar #collapse-side-menu li:hover {
  background-color: #405f88 !important;
}

#navbar-custom .b-sidebar #collapse-side-menu li {
  background-color: #001f48 !important;
}

#navbar-custom .b-sidebar #collapse-side-menu1 li:hover {
  background-color: #405f88 !important;
}

#navbar-custom .b-sidebar #collapse-side-menu1 li {
  background-color: #001f48 !important;
}

#navbar-custom .b-sidebar li a {
  color: #fff !important;
}

#navbar-custom img {
  padding: 12px 0;
  height: 60px;
  max-height: 100%;
}

#navbar-custom div.hamberger {
  width: 26px;
  height: 3px;
  margin: 6px 0;
  background-color: #032d64;
}
</style>
