<template>
  <div id="input-ndbx">
    <div class="ndbx-text-input">
      <input
        v-if="currency"
        :id="id"
        v-model="currencyValue"
        :type="type"
        :isEmpty="isEmpty"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder"
        style="color: #2c3e50; text-align: right"
        @keypress="isNumber"
      />
      <input
        v-else-if="number"
        :id="id"
        v-model="inputValue"
        :type="type"
        :isEmpty="isEmpty"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder"
        @keypress="isNumbercheck"
      />
      <input
        v-else
        :id="id"
        v-model="inputValue"
        :type="type"
        :isEmpty="isEmpty"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder"
      />
      <label :for="id" class="label-name">
        <span class="ndbx-content-name">
          {{ label }}
        </span>
      </label>
      <span :id="id + '-error-message'" class="ndbx-error-message" style="display: none">
        {{ errorMessage }}
      </span>
    </div>
  </div>
</template>

<script>
import { debounce } from 'debounce'
export default {
  name: 'InputNdbx',
  props: {
    id: {
      type: String,
      required: true,
      default: 'input',
      note: 'Id for the input',
    },
    type: {
      type: String,
      required: true,
      default: 'text',
      note: 'Type for the input',
    },
    label: {
      type: String,
      required: false,
      default: 'input label',
      note: 'Label for the input',
    },
    value: {
      type: [String, Number, Date],
      required: false,
      default: '',
      note: 'Value for the input',
    },
    isEmpty: {
      type: String,
      required: true,
      default: 'true',
      note: 'Attribute for the input',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Readonly attribute for the input',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable attribute for the input',
    },
    currency: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Currency attribute for the input',
    },
    number: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Number attribute for the input',
    },
    placeholder: {
      type: String,
      require: false,
      default: '',
      note: 'Placeholder text',
    },
    validationType: {
      type: String,
      required: false,
      default: '',
      note: 'Validation type for input',
    },
  },
  data() {
    return {
      errorMessage: this.label + 'ต้องไม่เป็นค่าว่าง',
      debouncedValidateTrimUserInput: debounce(function (data, inp) {
        this.validateTrimUserInput(data, inp)
      }, 500),
      debouncedValidateTrimUserIdInput: debounce(function (data, inp) {
        this.validateTrimUserIdInput(data, inp)
      }, 500),
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    currencyValue: {
      get() {
        if (this.value !== '') {
          // User is not modifying now. Format display value for user interface
          return this.value.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        } else return ''
      },
      set(modifiedValue) {
        // Recalculate value after ignoring "$" and "," in user input
        let newValue = parseFloat(modifiedValue.replace(/[^\d]/g, ''))
        // Ensure that it is not NaN
        if (isNaN(newValue)) {
          newValue = 0
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit('input', newValue)
      },
    },
  },
  watch: {
    inputValue(newVal) {
      // console.log('Input', newVal)
      const inp = document.getElementById(this.id)
      if (this.validationType == 'email') {
        let inpEmail = document.getElementById(this.id)
        let resultEmail = this.validateEmail(newVal)

        console.log(resultEmail)
        if (!resultEmail && newVal) {
          this.errorMessage = 'รูปแบบ' + this.label + 'ไม่ถูกต้อง'
          inpEmail.classList.add('invalid')
        } else inpEmail.classList.remove('invalid')
      } else if (this.validationType == 'phone') {
        let inpPhone = document.getElementById(this.id)
        let resultPhone = this.validatePhone(newVal)

        if (!resultPhone && newVal) {
          if (newVal.length > 10) {
            this.errorMessage = this.label + 'ต้องไม่เกิน 10 หลัก'
          } else this.errorMessage = 'รูปแบบ' + this.label + 'ไม่ถูกต้อง'

          inpPhone.classList.add('invalid')
        } else inpPhone.classList.remove('invalid')
      } else if (this.validationType == 'identityId') {
        let inpIdentityId = document.getElementById(this.id)
        let resultIdentityId = this.validateIdentityId(newVal)
        if (!resultIdentityId) {
          this.$emit('inputnewval', '')
        } else if (resultIdentityId && newVal.length > 13) {
          let Newval = newVal.slice(0, 13)
          this.$emit('inputnewval', Newval)
        } else if (newVal.length < 13 && newVal.length != 0) {
          inpIdentityId.classList.add('invalid')
          this.errorMessage = this.label + 'ต้องมีจำนวน 13 หลัก'
        } else if (newVal.length == 0 || newVal.length == 13) {
          inpIdentityId.classList.remove('invalid')
        }
      } else if (this.validationType == 'number') {
        let inpNumber = document.getElementById(this.id)

        if (isNaN(newVal)) {
          inpNumber.classList.add('invalid')
          this.errorMessage = this.label + 'ต้องเป็นตัวเลขเท่านั้น'
        } else inpNumber.classList.remove('invalid')
      } else if (this.validationType === 'validateTrimUserInput') {
        this.debouncedValidateTrimUserInput(newVal, inp)
      } else if (this.validationType === 'validateTrimUserIdInput') {
        this.debouncedValidateTrimUserIdInput(newVal, inp)
      }
    },
  },
  methods: {
    /**
     * Accept number input only
     */
    isNumber(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    isNumbercheck(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      var inputValue = evt.target.value
      if (inputValue.length >= 13 || (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    /**
     * Email validation
     * @param email
     * @returns true or false
     */
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      return re.test(String(email).toLowerCase())
    },

    /**
     * Phone validation
     * @param phone
     * @returns true or false
     */
    validatePhone(phone) {
      const re = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4}$/im

      return re.test(phone)
    },

    /**
     * Identity id validation
     * @param id
     * @returns true or false
     */
    validateIdentityId(id) {
      const re = /^[0-9]*$/

      return re.test(id)
    },
    validateTrimUserInput(data, inp) {
      const params = { username: data }
      this.$store.dispatch('staff/staffValidate', params).then((res) => {
        console.log('API staff validate ::', res)
        if (!res.data.isSuccess) {
          if (inp.classList.contains('has-error')) {
            inp.classList.remove('has-error')
          }
          this.errorMessage = 'ชื่อผู้ใช้งานนี้ถูกใช้งานแล้ว'
          inp.classList.add('invalid')
        } else {
          let resultUserName = this.checkTrim(data)
          if (resultUserName) {
            inp.classList.add('invalid')
            this.errorMessage = this.label + 'ต้องไม่มีช่องว่าง'
            this.$emit('inputnewval', resultUserName)
          } else {
            inp.classList.remove('invalid')
            this.$emit('inputnewval', resultUserName)
          }
        }
      })
    },
    validateTrimUserIdInput(data, inp) {
      const params = { staffId: data }
      this.$store.dispatch('staff/staffValidate', params).then((res) => {
        console.log('API staff validate ::', res)
        if (!res.data.isSuccess) {
          if (inp.classList.contains('has-error')) {
            inp.classList.remove('has-error')
          }
          this.errorMessage = 'รหัสพนักงานนี้ถูกใช้งานแล้ว'
          inp.classList.add('invalid')
        } else {
          let resultUserName = this.checkTrim(data)
          if (resultUserName) {
            inp.classList.add('invalid')
            this.errorMessage = this.label + 'ต้องไม่มีช่องว่าง'
            this.$emit('inputnewval', resultUserName)
          } else {
            inp.classList.remove('invalid')
            this.$emit('inputnewval', resultUserName)
          }
        }
      })
    },
    checkTrim(data) {
      const re = /\s/
      return re.test(data)
    },
  },
}
</script>

<style></style>
