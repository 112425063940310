import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueLoading from 'vue-loading-overlay'
import VCalendar from '@komdech/v-calendar'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Import VueLoading CSS
import 'vue-loading-overlay/dist/vue-loading.css'

// Import font
import './assets/font/stylesheet.css'

// Import ndbx button style
import './assets/ndbx/dropdownfilter.css'
import './assets/ndbx/select.css'
import './assets/ndbx/input.css'
import './assets/ndbx/radio.css'
import './assets/ndbx/button.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// User vue loading
Vue.use(VueLoading)
// Use VCalendar
Vue.use(VCalendar)

// Filter Thai bath
Vue.filter('thaiBath', function (value) {
  if (value) {
    return Number.parseFloat(value).toLocaleString('th-TH')
  } else return 0
})

// Filter Thai date
Vue.filter('thaiDate', function (value) {
  var d = new Date(value),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear() + 543

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month, year].join('/')
})

Vue.config.productionTip = false

/**
 * Axios interceptors
 */
axios.interceptors.request.use(async (config) => {
  const jwtToken = await store.getters['staff/getAccessToken']
  if (jwtToken != null) {
    config.headers.Authorization = 'Bearer ' + jwtToken
  }

  return config
})

Vue.config.productionTip = false

/**
 * Axios response interceptors
 */
let isRefreshing = false
let subscribers = []
axios.interceptors.response.use(
  (response) => {
    return response
  },
  async (err) => {
    const {
      config,
      response: { status, data },
    } = err

    const originalRequest = config

    if (status === 401 && data.code == 1401) {
      if (!isRefreshing) {
        isRefreshing = true
        await store
          .dispatch('staff/refreshToken')
          .then(({ status }) => {
            if (status === 200 || status == 204) {
              isRefreshing = false
            }
            subscribers = []
          })
          .catch(() => {
            console.error('Refresh Token Expired')
            store.dispatch('staff/destroyAuth')
          })
      }

      const requestSubscribers = new Promise((resolve) => {
        subscribeTokenRefresh(() => {
          resolve(axios(originalRequest))
        })
      })

      onRefreshed()

      return requestSubscribers
    }
    return Promise.reject(err)
  }
)

function subscribeTokenRefresh(cb) {
  subscribers.push(cb)
}

function onRefreshed() {
  subscribers.map((cb) => cb())
}

subscribers = []

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
