import Vue from 'vue'
import Vuex from 'vuex'
import staff from './modules/staff'
// import productNoDeduct from './modules/productNoDeduct'
// import productSme360 from './modules/productSme360'
// import address from './modules/address'
import smeFireInsurance from './modules/smeFireInsurance'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    staff,
    // productNoDeduct,
    // productSme360,
    // address,
    smeFireInsurance,
  },
  strict: process.env.NODE_ENV !== 'production',
})
