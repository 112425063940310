<template>
  <div id="app" class="noselect">
    <!-- Navbar -->
    <Navbar />

    <!-- Contens -->
    <router-view />
    <!-- Footter -->
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'

export default {
  name: 'App',
  components: {
    Navbar,
  },
}
</script>

<style>
#app {
  font-family: 'NeueFrutigerThaiModern-Rg', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* Arrow */
.arrow {
  border: solid #006192;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  vertical-align: sup;
}

/* Arrow direction */
.arrow.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.arrow.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrow.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* Transition animation */
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

/* pointer class*/
.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

/* User for unselect text */
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

/* Font style class */
.font-blod {
  font-family: 'NeueFrutigerThaiModern-Bd', Avenir, Helvetica, Arial, sans-serif;
}

.detail-preview-text-middle {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.detail-preview-text-item {
  align-self: center;
}

/* Close button */
.close-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 24px;
  height: 24px;
  opacity: 0.3;
  cursor: pointer;
}

.close-button:hover {
  opacity: 1;
}

.close-button:before,
.close-button:after {
  position: absolute;
  left: 12px;
  content: ' ';
  height: 24px;
  width: 2px;
  background-color: #333;
}

.close-button:before {
  transform: rotate(45deg);
}

.close-button:after {
  transform: rotate(-45deg);
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px !important;
  }
}
</style>
