import axios from 'axios'

/**
 * initial state
 */
const state = () => ({
  auth_info: {
    client_id: localStorage.getItem('client_id') || '',
    staff_id: localStorage.getItem('staff_id') || '',
    access_token: localStorage.getItem('access_token') || '',
    expires_in: localStorage.getItem('expires_in') || '',
    expiration: localStorage.getItem('expiration') || '',
    refresh_token: localStorage.getItem('refresh_token') || '',
    refresh_expires_in: localStorage.getItem('refresh_expires_in') || '',
    refresh_expiration: localStorage.getItem('refresh_expiration') || '',
  },
})

/**
 * getters
 */
const getters = {
  /**
   * User for Navigation guard
   */
  loggedIn(state) {
    return state.auth_info.access_token !== ''
  },

  /**
   * Get access token
   * @param {*} state
   * @returns
   */
  getAccessToken(state) {
    return state.auth_info.access_token
  },

  /**
   * Get refresh token
   * @param {*} state
   * @returns
   */
  getRefreshToken(state) {
    return state.auth_info.refresh_token
  },

  /**
   * Get auth info
   * @param {*} state
   * @returns state
   */
  authInfo(state) {
    return state.auth_info
  },
}

/**
 * mutations
 */
const mutations = {
  /**
   * Set user_info
   * @param {*} state
   * @param {*} data
   */
  setAuthInfo(state, data) {
    state.auth_info.client_id = data.clientId
    state.auth_info.staff_id = data.staffId
    state.auth_info.access_token = data.accessToken
    state.auth_info.expires_in = data.expiresIn
    state.auth_info.expiration = data.expiration
    state.auth_info.refresh_token = data.refreshToken
    state.auth_info.refresh_expires_in = data.refreshExpiresIn
    state.auth_info.refresh_expiration = data.refreshExpiration
  },

  /**
   * DetroyAuth state info
   * @param {*} state
   */
  destroyAuth(state) {
    state.auth_info.client_id = ''
    state.auth_info.staff_id = ''
    state.auth_info.access_token = ''
    state.auth_info.expires_in = ''
    state.auth_info.expiration = ''
    state.auth_info.refresh_token = ''
    state.auth_info.refresh_expires_in = ''
    state.auth_info.refresh_expiration = ''
  },
}

/**
 * actions
 */
const actions = {
  /**
   * Login Function
   * @param {*} context
   * @param {*} credentials
   */
  retrieveAuth(context, credentials) {
    return new Promise((resolve, reject) => {
      const params = {
        username: credentials.username,
        password: credentials.password,
        grantType: 'password',
      }

      axios
        .post(process.env.VUE_APP_LOGIN, params)
        .then(function (response) {
          // console.log(response.data);
          if (response.data.isSuccess) {
            const authInfo = response.data.data

            // Set to local storage
            localStorage.setItem('client_id', authInfo.clientId)
            localStorage.setItem('staff_id', authInfo.staffId)
            localStorage.setItem('access_token', authInfo.accessToken)
            localStorage.setItem('expires_in', authInfo.expiresIn)
            localStorage.setItem('expiration', authInfo.expiration)
            localStorage.setItem('refresh_token', authInfo.refreshToken)
            localStorage.setItem('refresh_expires_in', authInfo.refreshExpiresIn)
            localStorage.setItem('refresh_expiration', authInfo.refreshExpiration)

            // Set to state
            context.commit('setAuthInfo', authInfo)
            resolve(response)
          } else {
            if (
              response.data.code === '1010' &&
              response.data.message === 'Username and Password and license No information found'
            ) {
              resolve(response)
            } else reject(response.data.message)
          }
        })
        .catch(function (error) {
          console.log(error)
          reject(error)
        })
    })
  },

  /**
   * Refresh access token function
   * @param {*} context
   */
  refreshToken(context) {
    return new Promise((resolve, reject) => {
      const params = {
        refreshToken: context.getters.getRefreshToken,
        grantType: 'refresh_token',
      }

      axios
        .post(process.env.VUE_APP_LOGIN, params)
        .then(function (response) {
          if (response.data.isSuccess) {
            const authInfo = response.data.data

            // Set to local storage
            localStorage.setItem('client_id', authInfo.clientId)
            localStorage.setItem('staff_id', authInfo.staffId)
            localStorage.setItem('access_token', authInfo.accessToken)
            localStorage.setItem('expires_in', authInfo.expiresIn)
            localStorage.setItem('expiration', authInfo.expiration)
            localStorage.setItem('refresh_token', authInfo.refreshToken)
            localStorage.setItem('refresh_expires_in', authInfo.refreshExpiresIn)
            localStorage.setItem('refresh_expiration', authInfo.refreshExpiration)

            // Set to state
            context.commit('setAuthInfo', authInfo)
            resolve(response)
          }
        })
        .catch(function (error) {
          reject(error)
        })
    })
  },

  /**
   * Logout Function :: Clear state.auth, localStorage
   * @param {*} context
   */
  async destroyAuth(context) {
    if (context.getters.loggedIn) {
      const res = await axios.post(process.env.VUE_APP_REVOKE)

      if (res.data.isSuccess) {
        context.commit('destroyAuth')
        localStorage.clear()
        location.reload()
      } else {
        console.log(res)
        location.reload()
      }
    }
  },

  /**
   * Branch list
   * @param {*} context
   * @param {*} credentials
   * @returns
   */
  branchList() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_BRANCH_LIST)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * STAFF LIST
   */
  staffList(context, credentials) {
    return new Promise((resolve, reject) => {
      const params = {
        page: credentials.page,
        limit: credentials.limit,
        search: credentials.search,
        order: credentials.order,
        sort: credentials.sort,
      }

      axios
        .post(process.env.VUE_APP_USER_STAFF_LIST, params)
        .then((res) => {
          if (res.data.isSuccess) {
            resolve(res.data.data)
          }
        })
        .catch((err) => {
          reject(err)
          console.log(err)
        })
    })
  },

  /**
   * STAFF CREATE
   * @param {*} context
   * @param {*} credentials
   * @returns
   */
  staffCreate(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API create staff params ::', credentials)

      const params = {
        staffId: credentials.staffId,
        staffName: credentials.staffName,
        staffSurname: credentials.staffSurname,
        issuedate: credentials.issuedate,
        expiredate: credentials.expiredate,
        zoneCode: credentials.zoneCode,
        phoneNumber: credentials.phoneNumber,
        email: credentials.email,
        username: credentials.username,
        password: credentials.password,
        license: credentials.license,
        // branchCode: credentials.branchCode ? credentials.branchCode : '',
        // manager_id: credentials.manager_id ? credentials.manager_id : '',
        // title_name: credentials.title_name ? credentials.title_name : '',
        // citizen_id: credentials.citizen_id ? credentials.citizen_id : '',
        // position: credentials.position ? credentials.position : '',
        // channel: credentials.channel ? credentials.channel : '',
        // line_code: credentials.line_code ? credentials.line_code : '',
        // license_type: credentials.license_type ? credentials.license_type : '',
        // region_code: credentials.region_code ? credentials.region_code : '',
        // region_name: credentials.region_name ? credentials.region_name : '',
        // district_code: credentials.district_code ? credentials.district_code : '',
        // district_name: credentials.district_name ? credentials.district_name : '',
        // branchName: credentials.branchName ? credentials.branchName : '',
      }

      axios
        .post(process.env.VUE_APP_USER_STAFF_CREATE, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * STAFF VIEW
   */
  staffView(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API staff view params ::', credentials)
      const params = credentials.staffId ? '?staffId=' + credentials.staffId : ''

      axios
        .get(process.env.VUE_APP_USER_STAFF_VIEW + params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * STAFF UPDATE
   */
  staffUpdate(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API update staff ::', credentials)
      const params = {
        staffId: credentials.staffId,
        staffName: credentials.staffName,
        staffSurname: credentials.staffSurname,
        issuedate: credentials.issuedate,
        expiredate: credentials.expiredate,
        zoneCode: credentials.zoneCode,
        phoneNumber: credentials.phoneNumber,
        email: credentials.email,
        license: credentials.license,
        branchCode: credentials.branchCode ? credentials.branchCode : '',
        manager_id: credentials.manager_id ? credentials.manager_id : '',
        title_name: credentials.title_name ? credentials.title_name : '',
        citizen_id: credentials.citizen_id ? credentials.citizen_id : '',
        position: credentials.position ? credentials.position : '',
        channel: credentials.channel ? credentials.channel : '',
        line_code: credentials.line_code ? credentials.line_code : '',
        license_type: credentials.license_type ? credentials.license_type : '',
        region_code: credentials.region_code ? credentials.region_code : '',
        region_name: credentials.region_name ? credentials.region_name : '',
        district_code: credentials.district_code ? credentials.district_code : '',
        district_name: credentials.district_name ? credentials.district_name : '',
        branchName: credentials.branchName ? credentials.branchName : '',
      }
      console.log('params ', params)
      axios
        .post(process.env.VUE_APP_USER_STAFF_UPDATE + '/' + credentials.staffId, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * STAFF DELETE
   */
  staffDelete(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API delete staff ::', credentials)
      const params = { staffId: credentials.staffId }

      axios
        .post(process.env.VUE_APP_USER_STAFF_DELETE, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * STAFF VALIDATE
   */
  staffValidate(context, credentials) {
    return new Promise((resolve, reject) => {
      let params = credentials
      console.log('API staff validate ::', credentials)

      axios
        .post(process.env.VUE_APP_USER_STAFF_VALIDATE, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * STAFF UPLOAD
   * @param {*} context
   * @param {*} credentials
   * @returns
   */
  staffUpload(context, credentials) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('file', credentials.file)

      axios
        .post(process.env.VUE_APP_USER_STAFF_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * CMS LIST
   */
  cmsList(context, credentials) {
    return new Promise((resolve, reject) => {
      const params = {
        page: credentials.page,
        limit: credentials.limit,
        search: credentials.search,
        order: credentials.order,
        sort: credentials.sort,
      }

      axios
        .post(process.env.VUE_APP_USER_CMS_LIST, params)
        .then((res) => {
          if (res.data.isSuccess) {
            resolve(res.data.data)
          }
        })
        .catch((err) => {
          reject(err)
          console.log(err)
        })
    })
  },

  /**
   * CMS CREATE
   */
  cmsCreate(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API create cms params ::', credentials)

      const params = {
        staffId: credentials.staffId,
        staffName: credentials.staffName,
        staffSurname: credentials.staffSurname,
        issuedate: credentials.issuedate,
        expiredate: credentials.expiredate,
        zoneCode: credentials.zoneCode,
        phoneNumber: credentials.phoneNumber,
        email: credentials.email,
        username: credentials.username,
        password: credentials.password,
      }

      axios
        .post(process.env.VUE_APP_USER_CMS_CREATE, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * CMS UPDATE
   */
  cmsUpdate(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API update cms ::', credentials)
      const params = {
        staffId: credentials.staffId,
        staffName: credentials.staffName,
        staffSurname: credentials.staffSurname,
        issuedate: credentials.issuedate,
        expiredate: credentials.expiredate,
        zoneCode: credentials.zoneCode,
        phoneNumber: credentials.phoneNumber,
        email: credentials.email,
      }

      axios
        .post(process.env.VUE_APP_USER_CMS_UPDATE + '/' + credentials.staffId, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * CMS VIEW
   */
  cmsView(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API cms view params ::', credentials)
      const params = credentials.staffId ? '?staffId=' + credentials.staffId : ''

      axios
        .get(process.env.VUE_APP_USER_CMS_VIEW + params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * CMS DELETE
   */
  cmsDelete(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API delete cms ::', credentials)
      const params = { staffId: credentials.staffId }

      axios
        .post(process.env.VUE_APP_USER_CMS_DELETE, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * CMS VALIDATE
   */
  cmsValidate(context, credentials) {
    return new Promise((resolve, reject) => {
      let params = ''
      console.log('API cms validate ::', credentials)

      if (credentials.staffId) {
        params = { staffId: credentials.staffId }
      } else {
        params = { username: credentials.username }
      }

      axios
        .post(process.env.VUE_APP_USER_CMS_VALIDATE, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * CMS UPLOAD
   * @param {*} context
   * @param {*} credentials
   * @returns
   */
  cmsUpload(context, credentials) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('file', credentials.file)

      axios
        .post(process.env.VUE_APP_USER_CMS_UPLOAD, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * Get address list
   * @param provId string
   * @param bordId string
   * @return {Array}
   */
  getAddressList(context, credentials) {
    let params = credentials.param || ''

    console.log('Get Address Param', params)

    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_ADDRESS_LIST + '?' + params)
        .then((response) => {
          console.log('API Address List ::', response)
          if (response.data.isSuccess) {
            // isSuccess
            resolve(response.data.data)
          } else {
            // !isSuccess
            console.error(response.data)
            reject(response.data.message)
          }
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },
  historyList(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_RENEWAL_HISTORY, credentials)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  },
  uploadRenewalRenew(context, credentials) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      for (let i = 0; i < credentials.file.length; i++) {
        formData.append('file', credentials.file[i])
      }
      axios
        .post(process.env.VUE_APP_RENEWAL_UPLOAD_RENEW, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  uploadRenewalBanc(context, credentials) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      for (let i = 0; i < credentials.file.length; i++) {
        formData.append('file', credentials.file[i])
      }
      axios
        .post(process.env.VUE_APP_RENEWAL_UPLOAD_BANC, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          console.log(res)
          resolve(res)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    })
  },
  uploadRenewalPDF(context, credentials) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      for (let i = 0; i < credentials.file.length; i++) {
        const encodedFilename = encodeURIComponent(credentials.file[i].name)
        formData.append('files', credentials.file[i], encodedFilename)
      }
      axios
        .post(process.env.VUE_APP_RENEWAL_UPLOAD_PDF, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  uploadRenewalXLS(context, credentials) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      for (let i = 0; i < credentials.file.length; i++) {
        const encodedFilename = encodeURIComponent(credentials.file[i].name)
        // Ensure the key is 'file' to match the Postman key
        formData.append('file', credentials.file[i], encodedFilename)
      }

      const type = credentials.type
      const uploadURL = `${process.env.VUE_APP_RENEWAL_UPLOAD_XLS}/${type}`

      axios
        .post(uploadURL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  reportRawData(context, credential) {
    return new Promise((resolve, reject) => {
      // console.log('report cia params ::', credential)
      const params = {
        startDate: credential.startDate,
        endDate: credential.endDate,
      }
      console.log('report Excel params ::', params)
      axios
        .post(process.env.VUE_APP_RENEWAL_CMS_REPORT_RAW, params)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  RenewalView(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_RENEWAL_GETDETAIL + '/' + credentials.hash_id)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  getProductCode(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API list productcode params ::', credentials)
      axios
        .get(process.env.VUE_APP_RENEWAL_CMS_GET_PRODECT)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
