import axios from 'axios'
import router from '../../router'

/**
 * initial state
 */
const state = () => ({
  // todo
})

/**
 * getters
 */
const getters = {
  // todo
}

/**
 * mutations
 */
const mutations = {
  // todo
}

/**
 * actions
 */
const actions = {
  /**
   * List Quotation
   */
  /**
   * List Quotation
   */
  listQuotation(context, credentials) {
    return new Promise((resolve, reject) => {
      console.log('API list quotation params ::', credentials)
      axios
        .post(process.env.VUE_APP_SME_FIRE_INSURANCE_LIST_VIEW, credentials)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
  },

  /**
   * View Quotation Detail
   * @param smeFireInsuranceId
   * @return {array}
   */
  viewQuotationDetail(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_SME_FIRE_INSURANCE_VIEW + '/' + credentials.smeFireInsuranceId)
        .then((response) => {
          console.log('API view quotation detail ::', response)
          if (response.status == 200) {
            if (response.data.isSuccess) {
              if (
                Object.keys(response.data.data.premiumDetail).length === 0 &&
                response.data.data.customerDetail == null
              ) {
                resolve('No content')
                console.error('No content')
                router.push({ name: 'history' })
              }
              // isSuccess
              resolve(response.data.data)
            } else {
              // !isSuccess
              reject(response.data.message)
            }
          } else reject(response.data)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        })
    })
  },

  /**
   * Approve quotation function
   */
  approveQuotation(context, credential) {
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.VUE_APP_SME_FIRE_INSURANCE_APPROVE, {
          smeFireinsuranceId: credential.quotationId,
        })
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /**
   * Approve payment function
   */
  approvePayment(context, credential) {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_SME_FIRE_INSURANCE_APPROVE_PAYMENT + '/' + credential.quotationId)
        .then((result) => {
          resolve(result)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  reportOpus(context, credential) {
    return new Promise((resolve, reject) => {
      console.log('report opus params ::', credential)
      const params = {}
      let paths = ''
      if (credential.startDate && credential.endDate) {
        params.all = credential.all
        params.wht = credential.wht
        params.other = credential.other
        params.startDate = credential.startDate
        params.endDate = credential.endDate
        paths += params.all ? '?all=' + params.all : '?all=false'
        paths += params.wht ? '&wht=' + params.wht : ''
        paths += params.other ? '&other=' + params.other : ''
        paths += params.startDate ? '&startDate=' + params.startDate : ''
        paths += params.endDate ? '&endDate=' + params.endDate : ''
      } else {
        params.wht = credential.wht
        paths = params.wht ? '?wht=' + params.wht : ''
      }
      console.log('report opus paths ::', paths)
      console.log('report opus params ::', params)

      axios
        .get(process.env.VUE_APP_REPORT_OPUS + paths)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  importBillPayment(context, credential) {
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('type', credential.type)
      formData.append('file', credential.file)
      axios
        .post(process.env.VUE_APP_IMPORT_BILL_PAYMENT, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          console.log('import bill payment ::', res)
          resolve(res)
        })
        .catch((err) => {
          console.log('import bill payment err ::', err)
          reject(err)
        })
    })
  },

  reportRawData(context, credential) {
    return new Promise((resolve, reject) => {
      console.log('report raw data params ::', credential)
      axios
        .post(process.env.VUE_APP_REPORT_RAW, credential, {
          responseType: 'blob',
        })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  canclePayment(context, credential) {
    return new Promise((resolve, reject) => {
      console.log('Cancle Payment params ::', credential)
      axios
        .post(process.env.VUE_APP_CANCLE_PAYMENT, credential)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  // // async verifycustomer(context, credential) {
  // //   return new Promise((resolve, reject) => {
  // //     console.log('verifycustomer data params ::', credential)
  // //     axios
  // //       .post(process.env.VUE_APP_VERIFY_CUSTOMER, credential)
  // //       .then((res) => resolve(res))
  // //       .catch((err) => reject(err))
  // //   })
  // },
  async verifycustomer(content, credential) {
    try {
      const res = await axios.post(process.env.VUE_APP_VERIFY_CUSTOMER, credential)
      const data = res
      console.log('Response API Verify Customer::', data)

      if (res.status === 200 && res.data.isSuccess) {
        return res.data
      } else return false
    } catch (err) {
      console.error(err)
    }
  },

  getRejectList() {
    return new Promise((resolve, reject) => {
      axios
        .get(process.env.VUE_APP_SME_FIRE_INSURANCE_REJECT_LIST)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  rejectQuotation(context, credential) {
    return new Promise((resolve, reject) => {
      console.log('API reject quotation ::', context, credential)
      axios
        .post(process.env.VUE_APP_SME_FIRE_INSURANCE_REJECT, credential)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },

  /**
   * Updated Address Information
   */
  updateAddress(context, credentials) {
    return new Promise((resolve, reject) => {
      axios
        .put(process.env.VUE_APP_ADDRESS_UPDATE + '/' + credentials.addressId, {
          addressTypeId: Number(credentials.addressType),
          group_number: credentials.groupNumber,
          title_deed_number: credentials.deedNumber || null,
          building: credentials.building || null,
          room_number: credentials.roomNumber || null,
          floor: credentials.floor || null,
          alley: credentials.alley || null,
          road: credentials.road || null,
          province: Number(credentials.province),
          district: Number(credentials.district),
          sub_district: Number(credentials.subDistrict),
          province_name: credentials.provinceName,
          district_name: credentials.districtName,
          subdistrict_name: credentials.subDistrictName,
          postcode: credentials.postcode,
          country: credentials.country,
        })
        .then((response) => {
          console.log('API update address ::', response.data)
          if (response.data.isSuccess) {
            resolve(response.data.data)
          } else {
            console.error(response.data)
            reject(response.data.message)
          }
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },

  /**
   * Get new quotation
   */
  async genQuotation(context, credentials) {
    try {
      const res = await axios.get(
        process.env.VUE_APP_SME_FIRE_INSURANCE_GEN_QUOTATION + '/' + credentials.smeFireInsuranceId
      )
      console.log(res)
      if (res.status === 200) {
        return true
      }
    } catch (err) {
      console.error(err)
      return false
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
