import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

function load(component) {
  return () => import(`@/components/views/${component}.vue`)
}

function loadStaffModule(component) {
  return () => import(`@/components/staff-module/${component}.vue`)
}

function loadCmsModule(component) {
  return () => import(`@/components/cms-module/${component}.vue`)
}

const routes = [
  {
    name: 'login',
    path: '/',
    component: load('Login'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    name: 'history',
    path: '/history',
    component: load('History'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'quotationView',
    path: '/quotation-view/:smeFireInsuranceId',
    component: load('QuotationView'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'quotationEdit',
    path: '/quotation-edit/:smeFireInsuranceId',
    component: load('QuotationEdit'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'staff',
    path: '/staff-management/index',
    component: loadStaffModule('Index'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'staffCreate',
    path: '/staff-management/create',
    component: loadStaffModule('Create'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'staffView',
    path: '/staff-management/view/:staffId',
    component: loadStaffModule('View'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'staffUpdate',
    path: '/staff-management/update/:staffId',
    component: loadStaffModule('Update'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'staffUpload',
    path: '/staff-management/upload',
    component: loadStaffModule('Upload'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'cms',
    path: '/cms-management/index',
    component: loadCmsModule('Index'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'cmsCreate',
    path: '/cms-management/create',
    component: loadCmsModule('Create'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'cmsView',
    path: '/cms-management/view/:staffId',
    component: loadCmsModule('View'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'cmsUpdate',
    path: '/cms-management/update/:staffId',
    component: loadCmsModule('Update'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'cmsUpload',
    path: '/cms-management/upload',
    component: loadCmsModule('Upload'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'billPayment',
    path: '/bill-payment/upload',
    component: load('BillPaymentUpload'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'GetRiskPage',
    path: '/get-risk/view',
    component: load('GetRiskPage'),
  },
  {
    name: 'HistoryRenewalPage',
    path: '/history-renewal',
    component: load('HistoryRenewal'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'UploadRenewal',
    path: '/upload-renewal',
    component: load('UploadRenewal'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'DetailView',
    path: '/detail-view-renewal/view/:typePacket/:hashId',
    component: load('RenewalView'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'NotFoundPage',
    path: '/:catchAll(.*)',
    component: load('NotFoundPage'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 500)
    })
  },
})

/**
 * Check Authentication
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    if (!store.getters['staff/loggedIn']) {
      console.log('You are not logged in, go to login page...')
      next({
        name: 'login',
      })
    } else {
      console.log('Go to next page...')
      if (store.getters['staff/loggedIn']) {
        next()
      } else
        next({
          name: 'login',
        })
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    // this route dont't requires auth, check if logged in
    if (store.getters['staff/loggedIn']) {
      console.log('You are logged in, go to history page...')
      if (store.getters['staff/loggedIn']) {
        next({
          name: 'history',
        })
      } else
        next({
          name: 'login',
        })
    } else {
      console.log('Visitor Go to login page...')
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
